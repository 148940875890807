import FormContainer_FormContainerSkinComponent from '@wix/thunderbolt-elements/src/components/FormContainer/viewer/skinComps/FormContainerSkin/FormContainerSkin.skin';


const FormContainer_FormContainerSkin = {
  component: FormContainer_FormContainerSkinComponent
};


export const components = {
  ['FormContainer_FormContainerSkin']: FormContainer_FormContainerSkin
};


// temporary export
export const version = "1.0.0"
